.Navbar {
    background-color: #131518; /* Base color of the navbar */
    display: flex; /* Use flexbox layout */
    align-items: center; /* Vertically center items */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
    font-size: calc(10px + 2vmin);
  }
  
  .navbar-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex; /* Ensure horizontal layout */
  }
  
  .navbar-list li {
    margin-left: 20px;
  }
  
  .navbar-list li a {
    text-decoration: none;
    color: white;
  }
  
  .navbar-list li a:hover {
    color: #61dafb; /* Change link color on hover */
  }
  